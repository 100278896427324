import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
const baseURL = process.env.REACT_APP_API_BASE_URL;

function Menubar() {
  const [dnameOptions, setDnameOptions] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [sideNavWidth, setSideNavWidth] = useState("0");
  const [submenuVisible, setSubmenuVisible] = useState(false); 
  const navigate = useNavigate();

  const openNav = () => {
    setSideNavWidth("250px");
  };

  const closeNav = () => {
    setSideNavWidth("0");
    setSubmenuVisible(false);
  };

  useEffect(() => {
    const fetchDnameOptions = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/design-fetch`);
        setDnameOptions(response.data);
      } catch (error) {
        console.error("Error fetching design names:", error);
      }
    };

    fetchDnameOptions();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    if (value.length >= 3) {
      const filtered = dnameOptions.filter(option =>
        option.dname.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions([]);
    }
  };

  const handleDropdownClick = (did) => {
    navigate(`/design-preview/${did}`);
    closeNav();
  };

  return (
    <div className="header-div">
      <div className="div-row">
        <Link to="/">
          <img src={`${baseURL}/images/Inndus Logo_W.svg`} alt="Inndus Logo" className="symbol-logo2" />
        </Link>
        <input
          type='text'
          className='search'
          placeholder='Search'
          value={searchInput}
          onChange={handleInputChange}
        />
        {filteredOptions.length > 0 && (
          <ul className="custom-dropdown">
            {filteredOptions.map((option) => (
              <li key={option.did} onClick={() => handleDropdownClick(option.did)}>
                {option.dname}
              </li>
            ))}
          </ul>
        )}
        <div>
          <div
            id="mySidenav"
            className="sidenav"
            style={{ width: sideNavWidth, right: 0, left: "auto" }}
          >
            <button className="closebtn" onClick={closeNav}>
              &times;
            </button>
            <Link to="/">Home</Link>
            <button 
              className="btn btn-link menu-btn" 
              onClick={() => setSubmenuVisible(!submenuVisible)} 
              aria-expanded={submenuVisible}
              aria-controls="submenu"
              style={{ background: 'none', border: 'none', cursor: 'pointer', color:'#1f4b59' }}
            >
              Categories {submenuVisible ? '▲' : '▼'}
            </button>
            <div className={`collapse ${submenuVisible ? 'show' : ''}`} id="submenu">
              <ul className="submenu list-unstyled">
                <li>
                  <Link className='submenu-li' to="/design/1">Business Stationery</Link>
                </li>
                <li>
                  <Link className='submenu-li' to="/design/2">Marketing Collateral</Link>
                </li>
                <li>
                  <Link className='submenu-li' to="/design/3">Gifts</Link>
                </li>
                <li>
                  <Link className='submenu-li' to="/design/4">Decor</Link>
                </li>
              </ul>
            </div>
            <Link to="/about-us">About Us</Link>
            <Link to="/contact-us">Contact Us</Link>
          </div>

          <button className="button-menu" onClick={openNav}>
            &#9776; 
          </button>
        </div>
      </div>
    </div>
  );
}

export default Menubar;
