import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function Admin_lgoin() {
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    // Replace this with your actual login logic
    const validLoginId = 'Inndus';
    const validPassword = 'Inndus@24';

    if (loginId === validLoginId && password === validPassword) {
      // Set the cookie to expire in 2 hours
      Cookies.set('sessionId', 'your-session-id', { expires: 2 / 24 }); // 2 hours
      navigate('/admin-dashboard'); // Redirect to the dashboard or desired page
    } else {
      setError('Invalid login credentials. Please try again.');
    }
  };

  return (
    <div className="login-container">
          <div className="row login-row">
          <div className="card login-card">
      <p className='admin-title'>Inndus Admin</p>
     
    
      <form onSubmit={handleLogin}>
        <div className='login-id-div'>
          <label className='admin-label' htmlFor="loginId">Login ID:</label>
          <input
            type="text"
            id="loginId"
            className='form-control'
            value={loginId}
            onChange={(e) => setLoginId(e.target.value)}
            required
          />
        </div>
        <div className='password-id-div'>
          <label className='admin-label' htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
             className='form-control'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </form>
    </div>
    </div>
    </div>
  );
}

export default Admin_lgoin;
