import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './admin_navbar'; // Import Navbar if needed
import Footer from './admin_footer'; // Ensure your Footer component is imported
import Sidebar from './siderbar'; // Correct import for Sidebar
import { useParams } from 'react-router-dom';

function Admin_edit_product() {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const { pid } = useParams(); // Destructure pid directly from useParams
    const [products, setProducts] = useState({ pname: '', pid: '', ds1: '', image: null });
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });

    useEffect(() => {
        // Fetch product details
        const fetchProductDetails = async (productId) => {
            try {
                const response = await axios.post(`${baseURL}/api/product-fetch`, { productId });
              
                // Assuming response.data contains the fields we need
                setProducts(response.data[0]);
            } catch (error) {
                console.error('Error fetching product details:', error);
                setAlert({ show: true, message: 'Error fetching product details!', type: 'danger' });
            }
        };

        fetchProductDetails(pid); // Call fetchProductDetails with the current product ID
    }, [baseURL, pid]);

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setProducts((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onFileChange = (e) => {
        setProducts((prevState) => ({
            ...prevState,
            image: e.target.files[0],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('pname', products.pname);
        formData.append('ds1', products.ds1);
        formData.append('pid', products.pid);

        // Append the image only if a new one has been uploaded
        if (products.image) {
            formData.append('image', products.image);
        }

        try {
            // Update the product on the server
            const response = await axios.put(`${baseURL}/api/update-product/${pid}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            // Show success notification
            setAlert({ show: true, message: 'Product updated successfully!', type: 'success' });

            // Hide notification after 3 seconds
            setTimeout(() => {
                setAlert({ show: false, message: '', type: '' });
            }, 3000);

            // Optionally reset the form fields after successful submission
            setProducts({ dname: '', pid: '', ds1: '', image: null });
        } catch (error) {
            console.error(error);
            setAlert({ show: true, message: 'Error updating product!', type: 'danger' });

            // Hide notification after 3 seconds
            setTimeout(() => {
                setAlert({ show: false, message: '', type: '' });
            }, 3000);
        }
    };

    return (
        <div className="app-container">
            <Sidebar />
            <div className="main-content">
                <Navbar />
                <div className="content">
                    <div className="container">
                        {alert.show && (
                            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
                                {alert.message}
                                <button type="button" className="close" onClick={() => setAlert({ show: false, message: '', type: '' })}>
                                    <span>&times;</span>
                                </button>
                            </div>
                        )}
                        <form onSubmit={handleSubmit}>
                            <div className="form-group row">
                                <label>Product Name:</label>
                                <input
                                    type="text"
                                    name="pname" // Corrected to match the state property
                                    className="form-control"
                                    value={products.pname}
                                    onChange={onInputChange}
                                    required // Make the field required
                                />
                            </div>
                            <div className="form-group row">
                                <label>Image:</label>
                                <input
                                    type="file"
                                    name="image"
                                    className="form-control"
                                    onChange={onFileChange}
                                />
                            </div>
                            <div className="form-group row">
                                <label>ds1:</label>
                                <textarea
                                    name="ds1"
                                    className="form-control"
                                    value={products.ds1}
                                    onChange={onInputChange}
                                    required // Make the field required
                                ></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button> {/* Use button for better accessibility */}
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Admin_edit_product;
