import React from 'react';

function Admin_footer() {
  return (
    <div className="admin-footer">
            <p>&copy; 2024 Your Company. All rights reserved.</p>
        </div>
  )
}

export default Admin_footer