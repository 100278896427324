import React, { useState } from "react";
import { Link } from "react-router-dom";

function Siderbar() {
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    // State to manage submenu visibility
    const [productSubmenuVisible, setProductSubmenuVisible] = useState(false);
    const [designSubmenuVisible, setDesignSubmenuVisible] = useState(false);

    // Toggle function for product submenu
    const toggleProductSubmenu = () => {
        setProductSubmenuVisible(prev => !prev);
    };

    // Toggle function for design submenu
    const toggleDesignSubmenu = () => {
        setDesignSubmenuVisible(prev => !prev);
    };

    return (
        <>
            <div className="siderbar">
                <Link to={'/admin-dashboard'}>
                    <img src={`${baseURL}/images/logo.png`} alt="Inndus Logo" className="admin-logo" />
                </Link>
                <ul className="menu">
                    <li><Link to={'/admin-dashboard'} className="sider-menu">Dashboard</Link></li>

                    {/* Product Menu with Submenu */}
                    <li>
                        <button onClick={toggleProductSubmenu} className="sider-menu" style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                            Product {productSubmenuVisible ? '▲' : '▼'}
                        </button>
                        {productSubmenuVisible && (
                            <ul className="submenu-admin">
                                <li><Link to={'/admin-productpage'} className="sider-menu">Edit</Link></li>
                                <li><Link to={'/add-product'} className="sider-menu">Add</Link></li>
                            </ul>
                        )}
                    </li>

                    {/* Design Menu with Submenu */}
                    <li>
                        <button onClick={toggleDesignSubmenu} className="sider-menu" style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                            Design {designSubmenuVisible ? '▲' : '▼'}
                        </button>
                        {designSubmenuVisible && (
                            <ul className="submenu-admin">
                                <li><Link to={'/admin-designpage'} className="sider-menu">Edit </Link></li>
                                <li><Link to={'/add-design'} className="sider-menu">Add</Link></li>
                            </ul>
                        )}
                    </li>
                </ul>
            </div>
        </>
    );
}

export default Siderbar;
