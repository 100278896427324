import React from 'react'
import { Link } from 'react-router-dom';

function Desc() {
    const baseURL = process.env.REACT_APP_API_BASE_URL;

  return (
    <div className='container-fluid desc-container'>
        <div className='container'>
        {/* <hr /> */}
        <p className='desc-desc'>The best printed materials depend on your goals, whether it's marketing, branding, or personal use. They offer a wide range of uses and are highly effective in creating a strong visual presence, promoting products, and enhancing brand identity. Moreover, at Inndus, we understand the importance of deadlines. That's why we're committed to delivering your projects on time, every time, without compromising on quality. 
        </p>
        <p className='flavour-tile'>FLAVOUR OF THE MONTH </p>


        <div className='row flavour-product-row'>
            <div className='col-sm-3'>
                <div className='shadow-div'>
                <Link className='no-underline' to={'design-preview/65'}>
                <img src={`${baseURL}/images/Hardbound Boxes.webp`} className='flavour-images'  alt='flavour-images'/>
                <p className='flavour-product'>Hardbound Boxes</p>
                </Link>
                </div>
            </div>
            <div className='col-sm-3'>
            <div className='shadow-div'>
            <Link className='no-underline' to={'design-preview/67'}>
                <img src={`${baseURL}/images/MEASURING TAPE.webp`} className='flavour-images'  alt='flavour-images'/>
                <p className='flavour-product'>Measuring Tapes </p>
                </Link>
            </div>
            </div>
            <div className='col-sm-3'>
            <div className='shadow-div'>
            <Link className='no-underline' to={'design-preview/44'}>
                <img src={`${baseURL}/images/POST IT.webp`} className='flavour-images'  alt='flavour-images'/>
                <p className='flavour-product'>Post-its </p>
                </Link>
                </div>
            </div>
            <div className='col-sm-3'>
            <div className='shadow-div'>
            <Link className='no-underline' to={'design-preview/61'}>
                <img src={`${baseURL}/images/Calendar.webp`} className='flavour-images'  alt='flavour-images'/>
                <p className='flavour-product'>Calendars</p>
                </Link>
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Desc