import React from 'react'
import Header from './header'
import Header2 from './header2'
import Banner from './banner'
import Menubar from './menubar'
import Product from './product'
import Footer from './footer'
import Desc from './desc'
import Desc2 from './desc2'


function main() {
  return (
   
   <>
   {/* <Header /> */}
   <Header2 />
   {/* <Menubar /> */}
  <Banner />
  <Product />
  <Desc />
  <Desc2 />
  <Footer />
  
  
   </>
       
       
  )
}

export default main