import React from 'react';
import { Carousel } from 'react-bootstrap';
    const baseURL = process.env.REACT_APP_API_BASE_URL;


const BannerSlider = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  return (
    <Carousel interval={3000} pause="hover"> {/* Set interval for auto-slide */}
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={`${baseURL}/images/B1.webp`}
          alt="First slide"
        />
       
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={`${baseURL}/images/B2.webp`}
          alt="Second slide"
        />
        
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={`${baseURL}/images/B3.webp`}
          alt="Third slide"
        />
       
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={`${baseURL}/images/B4.webp`}
          alt="Fourth slide"
        />
        
      </Carousel.Item>
    </Carousel>
  );
};

export default BannerSlider;
