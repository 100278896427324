import './App.css';
import './index.css'; 
import './mobile.css';
import './admin/admin.css';
import './desktop.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './component/main';
import Design from './component/design';
import Insert_product from './admin/insert_product';
import { BrowserRouter as router, Routes, Route } from 'react-router-dom';
import Insert_design from './admin/insert_design';
import Preview from './component/preview';
import Test from './component/test';
import Dashboard from './admin/dashboard';
import AdminProductPage from './admin/admin_productpage';
import Admin_edit_product from './admin/admin_edit_product';
import AdminDesignPage from './admin/admin_designpage';
import Admin_edit_design from './admin/admin_edit-design';
import About from './component/about';
import Admin_lgoin from './admin/admin_login';
import Contact from './component/contact';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Main/>} />
        <Route path='/design/:productId' element={<Design/>} />
        <Route path='/add-product' element={<Insert_product/>} />
        <Route path='/add-design' element={<Insert_design/>} />
        <Route path='/design-preview/:did' element={<Preview/>} />
        <Route path='/test' element={<Test/>} />
        <Route path='/admin-dashboard' element={<Dashboard/>} />
        <Route path='/admin-productpage' element={<AdminProductPage/>} />
        <Route path='/edit-product/:pid' element={<Admin_edit_product/>} />
        <Route path='/admin-designpage' element={<AdminDesignPage/>} />
        <Route path='/edit-design/:did' element={<Admin_edit_design/>} />
        <Route path='/about-us' element={<About/>} />
        <Route path='/contact-us' element={<Contact/>} />
        <Route path='/admin-login' element={<Admin_lgoin/>} />

        
      </Routes>
    </div>
  );
}

export default App;
