import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Header2 from './header2';
import Footer from './footer';

function Preview() {
  const vi = useRef(null);
  const navigate = useNavigate();
  const did = useParams();
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  
  const [design, setDesigns] = useState([]);
  const [order, setOrder] = useState({
    u_name: '',
    email: '',
    contact: '',
    attach: null,
    desc: ''
  });
  const [notification, setNotification] = useState({ message: '', type: '' });
  const [errors, setErrors] = useState({}); // State for input errors

  useEffect(() => {
    const fetchDesignDetails = async (e) => {
      try {
        const response = await axios.post(`${baseURL}/api/design-fetch-did`, did);
        setDesigns(response.data[0]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchDesignDetails();
  }, [did]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrder({
      ...order,
      [name]: value,
    });
    setErrors((prev) => ({ ...prev, [name]: false })); // Remove error if input is updated
  };

  const handleFileChange = (e) => {
    setOrder({
      ...order,
      attach: e.target.files[0],
    });
    setErrors((prev) => ({ ...prev, attach: false })); // Remove error for file input
  };

  const handlSubmit = async (e) => {
    e.preventDefault();

    // Validate inputs
    let formErrors = {};
    if (!order.u_name) formErrors.u_name = true;
    if (!order.email) formErrors.email = true;
    if (!order.contact) formErrors.contact = true;
    if (!order.attach) formErrors.attach = true;

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const formdata = new FormData();
    formdata.append('pid', design.pid);
    formdata.append('did', design.did);
    formdata.append('u_name', order.u_name);
    formdata.append('email', order.email);
    formdata.append('contact', order.contact);
    formdata.append('attach', order.attach);
    formdata.append('desc', order.desc);

    try {
      const response = await axios.post(`${baseURL}/api/placed-order`, formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
        setNotification({ message: 'Order placed successfully!', type: 'success' });
        setTimeout(() => {
          navigate('/'); // Redirect to the home route after 3 seconds
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Header2 />
      <div className="container preview-container">
        <h4 className="title-get-quote">GET A QUOTE</h4>
        {notification.message && (
          <div className={`alert alert-success notification ${notification.type}`}>
            {notification.message}
          </div>
        )}
        <div className="row">
          <div className="col-sm-6">
            <img src={`${baseURL}/images/${design.image}`} className="design-image" alt="Design" />
          </div>
          <div className="col-sm-6">
            <p className="preview-design-name">{design.dname}</p>
            <div className="quote-div">
              <form onSubmit={handlSubmit}>
                <div className={`form-group row form-control-group ${errors.u_name ? 'input-error' : ''}`}>
                  <label className="label-get-qoute">Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="u_name"
                    value={order.u_name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={`form-group row form-control-group ${errors.contact ? 'input-error' : ''}`}>
                  <label className="label-get-qoute">Contact:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="contact"
                    value={order.contact}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={`form-group row form-control-group ${errors.email ? 'input-error' : ''}`}>
                  <label className="label-get-qoute">Email:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    value={order.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={`form-group row form-control-group ${errors.attach ? 'input-error' : ''}`}>
                  <label className="label-get-qoute">Attach Files:</label>
                  <input type="file" className="form-control" name="attach" onChange={handleFileChange} />
                </div>
                <div className="form-group row form-control-group">
                  <label className="label-get-qoute">Description:</label>
                  <textarea
                    className="form-control"
                    name="desc"
                    value={order.desc}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <input type="submit" className="btn enquire-button" name="submit" value="Submit" />
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Preview;
