import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import Cookies from 'js-cookie';

function Admin_navbar() {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    // Check if the cookie exists
    const sessionId = Cookies.get('sessionId');
    if (!sessionId) {
      navigate('/admin-login'); // Redirect to login page if cookie does not exist
    }
  }, [navigate]); // Add navigate to the dependency array

  const handleLogout = () => {
    Cookies.remove('sessionId'); // Remove the cookie on logout
    window.location.href = '/admin-login'; // Redirect to login page
  };

  return (
    <div className='navbar-container'>
      <button className='logout-btn' onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default Admin_navbar;
