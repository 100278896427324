import React from 'react';
import { Link } from 'react-router-dom';

function Desc2() {
    const baseURL = process.env.REACT_APP_API_BASE_URL;

  return (
    <div className='container-fluid desc2-container'>
        <div className='container'>
        {/* <hr /> */}
        <p className='desc2-desc2'>From business cards that make a lasting impression to vibrant posters that captivate audiences, we offer a comprehensive range of printing services tailored to meet your needs. Our state-of-the-art equipment and skilled team ensure that every project is executed with passion and professionalism.</p>
        <p className='flavour-tile'>ALL-TIME FAVOURITES </p>


        <div className='row' >
            <div className='col-sm-3'>
            <div className='shadow-div'>
            <Link className='no-underline' to={'design-preview/1'}>
                <img src={`${baseURL}/images/Business Cards.webp`} className='flavour-images'  alt='flavour-images'/>
                <p className='flavour-product'>Business cards</p>
                </Link>
                </div>
            </div>
            
            <div className='col-sm-3'>
            <div className='shadow-div'>
            <Link className='no-underline' to={'design-preview/32'}>
                <img src={`${baseURL}/images/Tote Bag.webp`} className='flavour-images'  alt='flavour-images'/>
                <p className='flavour-product'>Tote Bags</p>
                </Link>
                </div>
            </div>
            <div className='col-sm-3'>
            <div className='shadow-div'>
            <Link className='no-underline' to={'design-preview/4'}>
                <img src={`${baseURL}/images/Branded Pads.webp`} className='flavour-images'  alt='flavour-images'/>
                <p className='flavour-product'>Branded pads</p>
                </Link>
                </div>
            </div>
            <div className='col-sm-3'>
            <div className='shadow-div'>
            <Link className='no-underline' to={'design-preview/8'}>
                <img src={`${baseURL}/images/Labels & Stickers.webp`} className='flavour-images'  alt='flavour-images'/>
                <p className='flavour-product'>Labels & Stickers</p>
                </Link>
                </div>
            </div>
        </div>
        <p className='desc2-desc2'>Whether you're a small business looking to make a big impact or a large corporation seeking top-notch printing solutions, or even an individual looking for personal or customised gifts, we've got you covered. Let us be your partner in print, turning your ideas into reality and trust us to make a lasting impression with every print.</p>
        <p className='desc2-desc3'>Welcome to Inndus, where we are passionate about printing.</p>

        </div>
    </div>
  )
}

export default Desc2