import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Header2 from './header2'
import Menubar from './menubar'
import { useParams } from 'react-router-dom';
import Footer from './footer';
import { Link } from 'react-router-dom';


function Design() {
const productId = useParams();
const [designs, setDesigns]= useState([]);
const [products, setProducts]= useState([]);
const baseURL = process.env.REACT_APP_API_BASE_URL;



useEffect(() => {
    const fetchDesign = async() => {
        try{
        const response = await axios.post(`${baseURL}/api/design-fetch`, productId);
        setDesigns(response.data);
        }
        catch(err){
            console.error('Error fetching in the design');
        }

    }

    fetchDesign();
}, [productId]);


useEffect(() => {
  const productName = async() => {
      try{
      const response = await axios.post(`${baseURL}/api/product-fetch`, productId);
      setProducts(response.data[0]);
      }
      catch(err){
          console.error('Error fetching in the design');
      }

  }

  productName();
}, [productId]);
  return (
    <>
   
    <Header2 />
    <div className='container design-container'>
       <div>
       <ul className='design-menu'>
        <li><Link to={`/`} className='submenu'>Home </Link></li>
        <li className='submenu'>/</li>
        <li className='submenu'>{products.pname}</li>
       </ul>
       </div>

        <div className='row design-row'>
            {designs.map((design) => (
              <div className='col-sm-3 design-sm'>
                <div className='design-div-main'> 
                  <Link className='no-underline' to={`/design-preview/${design.did}`} >
                <img src={`${baseURL}/images/${design.image}`} className='design-image' />
                <p className='design-name' style={{ textDecoration: 'none' }}>{design.dname}</p>
                </Link>
                {/* <p className='design-ds1'>{design.ds1}</p> */}
                </div>
                </div>
            ))
            }
        </div>
    </div>
    <Footer />
    </>
  )
}

export default Design