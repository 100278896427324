import React, { useEffect, useState } from 'react';
import Navbar from './admin_navbar'; // Import Navbar if needed
import Footer from './admin_footer'; // Ensure your Footer component is imported
import Sidebar from './siderbar'; // Correct import for Sidebar
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Correct import for useNavigate

const AdminDesignPage = () => {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Base URL from environment variables
    const navigate = useNavigate(); // Use useNavigate for navigation
    const [designs, setDesigns] = useState([]); // State for designs
    const [searchTerm, setSearchTerm] = useState(''); // State for search term

    // Function to fetch designs from the API
    const fetchDesigns = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/design-fetch`); // Fetch designs from the API
            setDesigns(response.data); // Set designs in state
        } catch (error) {
            console.error('Error fetching designs:', error); // Log any errors
        }
    };

    // Function to navigate to the edit page for a specific design
    const editDesign = (did) => {
        navigate(`/edit-design/${did}`); // Navigate to the edit page using the design ID
    };

    // Function to delete a design
    const deleteDesign = async (did) => {
        try {
            await axios.delete(`${baseURL}/api/design/${did}`); // Call the API to delete the design
            fetchDesigns(); // Re-fetch designs after deletion
        } catch (error) {
            console.error('Error deleting design:', error); // Log any errors
        }
    };

    // Fetch designs when the component mounts
    useEffect(() => {
        fetchDesigns();
    }, []);

    // Filter designs based on the search term
    const filteredDesigns = designs.filter(design =>
        design.dname.toLowerCase().includes(searchTerm.toLowerCase()) // Filter by product name
    );

    return (
        <div className="app-container">
            <Sidebar /> {/* Sidebar for navigation */}
            <div className="main-content">
                <Navbar /> {/* Navbar for the admin section */}
                <div className="content">
                    <p className='design-title'>Design</p>

                    {/* Search Input */}
                    <input
                        type="text"
                        placeholder="Search designs..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                        style={{ marginBottom: '20px', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} // Styled search input
                    />

                    {/* Designs Table */}
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}> {/* Basic styling for the table */}
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Design Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredDesigns.map(design => (
                                <tr key={design.did} style={{ borderBottom: '1px solid #ddd' }}> {/* Border for each row */}
                                    <td>
                                        <img src={`${baseURL}/images/${design.image}`} alt={design.dname} style={{ width: '50px', height: '50px' }} />
                                    </td>
                                    <td>{design.dname}</td>
                                    <td>
                                        {/* Edit and Delete buttons */}
                                        <span onClick={() => editDesign(design.did)} style={{ marginRight: '10px', cursor:'pointer' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
  <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z"/>
</svg></span>
                                        <span onClick={() => deleteDesign(design.did)} style={{ marginRight: '10px', cursor:'pointer' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
</svg></span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer /> {/* Footer for the admin section */}
        </div>
    );
};

export default AdminDesignPage;
