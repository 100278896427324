import React, { useEffect, useState } from 'react';
import Navbar from './admin_navbar'; // Import Navbar if needed
import Footer from './admin_footer'; // Ensure your Footer component is imported
import Sidebar from './siderbar'; // Correct import for Sidebar
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Correct import for useNavigate

const AdminProductPage = () => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate(); // Use useNavigate instead of useNavigation
    const [products, setProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    // Function to fetch products from the API
    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/product`); // Adjust the URL as needed
            setProducts(response.data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const editProduct = async (pid) => {
        navigate(`/edit-product/${pid}`); // Navigate to the edit page
    };

    // Function to delete a product
    const deleteProduct = async (pid) => {
        try {
            await axios.delete(`${baseURL}/api/products/${pid}`); // Adjust the URL as needed
            fetchProducts(); // Re-fetch products after deletion
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };

    // Fetch products when the component mounts
    useEffect(() => {
        fetchProducts();
    }, []);

    // Filter products based on the search term
    const filteredProducts = products.filter(product =>
        product.pname.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="app-container">
            <Sidebar />
            <div className="main-content">
                <Navbar />
                <div className="content">
                <p className='product-title'>Product</p>

                    {/* Search Input */}
                    <input
                        type="text"
                        placeholder="Search products..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '20px', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} // Styled search input
                    />

                    {/* Products Table */}
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}> {/* Basic styling for the table */}
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Product Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredProducts.map(product => (
                                <tr key={product.pid} style={{ borderBottom: '1px solid #ddd' }}> {/* Border for each row */}
                                    <td>
                                        <img src={`${baseURL}/images/${product.image}`} alt={product.pname} style={{ width: '50px', height: '50px' }} />
                                    </td>
                                    <td>{product.pname}</td>
                                    <td>
                                        {/* Edit and Delete buttons */}
                                        <span onClick={() => editProduct(product.pid)} style={{ marginRight: '10px', cursor:'pointer' }}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
  <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z"/>
</svg></span>
                                        <span onClick={() => deleteProduct(product.pid)} style={{ marginRight: '10px', cursor:'pointer' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
</svg></span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AdminProductPage;
