import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Navbar from './admin_navbar';
import Footer from './admin_footer';
import Siderbar from './siderbar';
function Insert_product() {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const [product, setproduct]=useState({
        pname:'',
        image:null,
        ds1:''
    })
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });


    const handleInputChange=(e)=>{
        const {name, value} = e.target;
        setproduct({
            ...product,
            [name]:value,
        });

    };

    

    const handleFileChange = (e) => {
        setproduct({
            ...product,
            image:e.target.files[0],
        });
    };

    const handleSubmit = async(e) =>{
        e.preventDefault();


        const formData= new FormData();
        formData.append('pname', product.pname);
        formData.append('image', product.image);
        formData.append('ds1', product.ds1);
    

    try{
        const response = await axios.post(`${baseURL}/add-product`, formData, {
            headers : {
                "Content-Type":"multipart/form-data",
            },
            
        });

        // Show success notification
        setAlert({ show: true, message: 'Product added successfully!', type: 'success' });

        // Reset input fields
        setproduct({ pname: '', image: null, ds1: '' });

        // Hide notification after 3 seconds
        setTimeout(() => {
            setAlert({ show: false, message: '', type: '' });
        }, 3000);
       
    } catch(error){
        console.error(error);

        setAlert({ show: true, message: 'Error adding product!', type: 'danger' });

        // Hide notification after 3 seconds
        setTimeout(() => {
            setAlert({ show: false, message: '', type: '' });
        }, 3000);
    }
};

  return (

    <div className="app-container">
            <Siderbar />
            <div className="main-content">
                <Navbar />
                <div className="content">
                <div className='container'>
                {alert.show && (
                            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
                                {alert.message}
                                <button type="button" className="close" onClick={() => setAlert({ show: false, message: '', type: '' })}>
                                    <span>&times;</span>
                                </button>
                            </div>
                        )}
    <form onSubmit={handleSubmit}> 
    <div className='form-group row'>
        <label>Product Name :</label>
        <input type='text' className='form-control' name='pname' value={product.pname} onChange={handleInputChange} />
    </div>
    <div className='form-group row'>
        <label>Image :</label>
        <input type='file' className='form-control' name='image' onChange={handleFileChange} a/>
    </div>
    <div className='form-group row'>
        <label>Description :</label>
       <textarea name='ds1' className='form-control' value={product.ds1} onChange={handleInputChange}></textarea>
    </div>
    <input type='submit' name='submit' />
    </form>
   </div>
                   
                </div>
                <Footer />
            </div>
        </div>


   
  )
}

export default Insert_product