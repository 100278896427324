import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './admin_navbar'; // Import Navbar if needed
import Footer from './admin_footer'; // Ensure your Footer component is imported
import Sidebar from './siderbar'; // Correct import for Sidebar

function Insert_design() {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const [products, setProducts] = useState([]);
    const [designs, setDesigns] = useState({
        dname: '',
        pid: '',
        image: null,
        ds1: '',
    });
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });


    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/product`);
                setProducts(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchProducts();
    }, []);

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setDesigns({
            ...designs,
            [name]: value,
        });
    };

    const onFileChange = (e) => {
        setDesigns({
            ...designs,
            image: e.target.files[0],
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('dname', designs.dname);
        formData.append('ds1', designs.ds1);
        formData.append('image', designs.image);
        formData.append('pid', designs.pid);

        try {
            const response = await axios.post(`${baseURL}/add-design`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
           
             // Show success notification
        setAlert({ show: true, message: 'Product added successfully!', type: 'success' });

       
        // Hide notification after 3 seconds
        setTimeout(() => {
            setAlert({ show: false, message: '', type: '' });
        }, 3000);
            // Optionally reset the form fields after successful submission
            setDesigns({
                dname: '',
                pid: '',
                image: null,
                ds1: '',
            });
        } catch (error) {
            console.error(error);
            setAlert({ show: true, message: 'Error adding product!', type: 'danger' });

            // Hide notification after 3 seconds
            setTimeout(() => {
                setAlert({ show: false, message: '', type: '' });
            }, 3000);
        }
    };

    return (
        <div className="app-container">
            <Sidebar />
            <div className="main-content">
                <Navbar />
                <div className="content">
                    <div className="container">
                    {alert.show && (
                            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
                                {alert.message}
                                <button type="button" className="close" onClick={() => setAlert({ show: false, message: '', type: '' })}>
                                    <span>&times;</span>
                                </button>
                            </div>
                        )}
                        <form onSubmit={handleSubmit}>
                            <div className="form-group row">
                                <label>Design Name:</label>
                                <input
                                    type="text"
                                    name="dname"
                                    className="form-control"
                                    value={designs.dname}
                                    onChange={onInputChange}
                                    required // Make the field required
                                />
                            </div>
                            <div className="form-group row">
                                <label>Select Category:</label>
                                <select
                                    className="form-control"
                                    name="pid"
                                    value={designs.pid}
                                    onChange={onInputChange}
                                    required // Make the field required
                                >
                                    <option value="" disabled>Select a product</option> {/* Placeholder option */}
                                    {products.map((product) => (
                                        <option key={product.pid} value={product.pid}>
                                            {product.pname}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group row">
                                <label>Image:</label>
                                <input
                                    type="file"
                                    name="image"
                                    className="form-control"
                                    onChange={onFileChange}
                                    required // Make the field required
                                />
                            </div>
                            <div className="form-group row">
                                <label>ds1:</label>
                                <textarea
                                    name="ds1"
                                    className="form-control"
                                    value={designs.ds1}
                                    onChange={onInputChange}
                                    required // Make the field required
                                ></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button> {/* Use button for better accessibility */}
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Insert_design;
