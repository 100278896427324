import React, { useEffect, useState } from 'react';
import Navbar from './admin_navbar'; 
import Footer from './admin_footer'; 
import Sidebar from './siderbar'; 
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom'; 

const Dashboard = () => {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Base URL from environment variables
    const navigate = useNavigate(); // Use useNavigate for navigation
    const [orders, setOrders] = useState([]); // State for orders
    const [searchTerm, setSearchTerm] = useState(''); // State for search term

    // Function to fetch orders from the API
    const fetchOrders = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/orders`); // Fetch orders from the API
            const ordersData = response.data;
            
            // Fetch product and design details for each order
            const updatedOrders = await Promise.all(ordersData.map(async (order) => {
                const productResponse = await axios.post(`${baseURL}/api/admin-product-fetch`, { pid: order.pid }); // Post pid for product data
                const designResponse = await axios.post(`${baseURL}/api/admin-design-fetch`, { did: order.did });   // Post did for design data
                
                // Return the merged order data with product and design details
                return {
                    ...order,
                    productName: productResponse.data.pname, // Assuming product response has 'name' field
                    designName: designResponse.data.dname   // Assuming design response has 'name' field
                };
            }));

            setOrders(updatedOrders); // Set orders in state with product and design details
        } catch (error) {
            console.error('Error fetching orders:', error); // Log any errors
        }
    };

    // Fetch orders when the component mounts
    useEffect(() => {
        fetchOrders();
    }, []);

    // Function to edit an order (adjust based on actual logic)
    const editOrder = (orderId) => {
        // Navigate to edit order page with the orderId
        navigate(`/admin/edit-order/${orderId}`);
    };

    // Function to delete an order (adjust based on actual logic)
    const deleteOrder = async (orderId) => {
        try {
            await axios.delete(`${baseURL}/api/orders/${orderId}`);
            // Refetch the orders after deletion
            fetchOrders();
        } catch (error) {
            console.error('Error deleting order:', error);
        }
    };

    // Filter orders based on the search term
    const filteredOrders = orders.filter(order =>
        order.u_name.toLowerCase().includes(searchTerm.toLowerCase()) // Filter by user name
    );

    return (
        <div className="app-container">
            <Sidebar /> {/* Sidebar for navigation */}
            <div className="main-content">
                <Navbar /> {/* Navbar for the admin section */}
                <div className="content">
                    <p className='orderPage-title'>Admin Order Page</p>

                    {/* Search Input */}
                    <input
                        type="text"
                        placeholder="Search orders by user name..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                        style={{ marginBottom: '20px', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} // Styled search input
                    />

                    {/* Orders Table */}
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}> {/* Basic styling for the table */}
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>User Name</th>
                                <th>Product Name</th> {/* Display product name */}
                                <th>Design Name</th>  {/* Display design name */}
                                <th>Description</th>
                                <th>Email</th>
                                <th>Contact</th>
                                <th>Attachment</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredOrders.map(order => (
                                <tr key={order.order_id} style={{ borderBottom: '1px solid #ddd' }}> {/* Border for each row */}
                                    <td>{order.order_id}</td>
                                    <td>{order.u_name}</td>
                                    <td>{order.productName}</td> {/* Show product name */}
                                    <td>{order.designName}</td>   {/* Show design name */}
                                    <td>{order.desc}</td>
                                    <td>{order.email}</td>
                                    <td>{order.contact}</td>
                                    <td>
                                        {/* Display image if attach field has a value */}
                                        {order.attach ? (
                                            <Link to={`${baseURL}/order/${order.attach}`} target='_blank' >
                                            <img src={`${baseURL}/order/${order.attach}`} alt="Attachment" style={{ width: '50px', height: '50px' }} />
                                            </Link>
                                        ) : 'No Attachment'}
                                    </td>
                                    <td>{new Date(order.date).toLocaleDateString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer /> {/* Footer for the admin section */}
        </div>
    );
};

export default Dashboard;
