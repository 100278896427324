import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';


function Product() {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const [products, setProducts] = useState([]); // Renamed to 'products' for clarity
    const [loading, setLoading] = useState(true); // For showing loading state
    const [error, setError] = useState(null); // For handling errors

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/product`); // Append endpoint path if needed
                setProducts(response.data);
            } catch (err) {
                setError(err.message || 'An error occurred');
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, [baseURL]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div>
            
           
           
            <div className='container product-container'>
                <p className='title-about'>At Inndus, we’re dedicated to delivering high-quality printing solutions tailored to meet the needs of businesses and individuals (www.myavataar.com) alike. Whether you’re looking for sleek business cards, eye-catching brochures, or large-scale banners, we bring your vision to life with precision, creativity, and attention to detail. </p>
<p className='title-about'>From small projects to bulk orders, we provide a seamless process from design to delivery, ensuring your brand stands out. Let us handle your printing needs while you focus on growing your business. We’re here to help you make a lasting impression!</p>

<p className='title-about'>Explore our services today and discover why businesses trust Inndus for all their printing needs. Let’s create something extraordinary together</p>

            
                <div className='row product-row'>
                    <div className='col-sm-12'>
                    <p className='category-title'><span className='category-title2'>THINGS WE DO</span></p>                    </div>
              
                    {products.map((product) => (
                       <div className='col-sm-3 product-div'>
                        <Link className='no-underline' to={`/design/${product.pid}`}>
                        <img src={`${baseURL}/images/${product.image}`} className='product-image' />
                        <p className='product-name'>{product.pname}</p>
                        </Link>

                       </div>
                    ))}
               

                </div>
                </div>
        </div>
    );
}

export default Product;
